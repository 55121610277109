import { AxiosError, AxiosResponse } from "axios";
import axiosClient, { LONG_POLL, SHORT_POLL } from "~/etc/axiosClient";
import {
    useMutation,
    UseMutationOptions,
    useQuery,
    useQueryClient,
    UseQueryOptions,
} from "react-query";
import { Company, User, USER_KEY } from "./useUser";
import _ from "lodash";
import { ProductTrial, TrialStatus } from "~/data/productTrials";
import queryString from "querystring";
import { useBrowserCapability } from "~/state/BrowserCapabilityContext";
import { Member, Workspace } from "./useWorkspace";
import { useAuth } from "~/state/auth";
import { LDFlags, useFlags } from "~/components/LaunchDarkly";
import { Product } from "./useProducts";
import { DealFilterParameters } from "~/v2/components/deals-table/StatusFilter";

export interface Account {
    first_name: string;
    last_name: string;
    email: string;
}

export interface CRMDealData {
    account_name: string;
    accounts: Account[];
    external_id: string;
}

export interface DealUser extends Member {
    workspace_role: string;
    is_active: boolean;
    last_email_invite_sent_at: string | null;
    email_invite_timestamps: Date[];
    send_verification_email: boolean | null;
}

export interface DealForm {
    lead_id?: string;
    name?: string;
    stakeholders?: Partial<DealUser>[];
    send_verification_email?: boolean;
    external_crm_id?: string;
    expiry_date?: string;
    product_slug: string;
    convert_use_case_ids?: string[];
}

export interface FilterForm {
    activation_status: string[] | undefined;
}

export interface CreateDealMutation extends DealForm {
    skip_existing_check?: boolean;
}

export interface Lead {
    lead_id: string;
    name: string;
    primary_user?: User;
    company: Company;
    status: string;
    created_at: string;
    lead_engaged: boolean;
    trial_status: string;
    trial?: ProductTrial;
    workspace: Workspace;
    external_crm_id?: string;
    is_active: boolean;
    crm_is_won?: boolean;
    crm_is_lost?: boolean;
    magic_link?: string;
    archived?: boolean;
}

export interface ProductTrialLeadRow {
    trial_id: string;
    product: Pick<
        Product,
        | "name"
        | "product_id"
        | "created_at"
        | "slug"
        | "is_active"
        | "extension_required"
    >;
    status: TrialStatus;
    start_url: string | null;
    direct_start_url: string | null;
    proxy_start_url: string | null;
    expired: boolean;
    upstream_origin: string;
    expires_at: any;
    use_extension_if_installed: boolean;
}

export interface LeadRow {
    lead_id: string;
    salesperson_name: string;
    salesperson_email: string;
    partner_company_name?: string;
    name: string;
    status: string;
    created_at: string;
    trial?: ProductTrialLeadRow;
    external_crm_id?: string;
    is_active: boolean;
    crm_is_won?: boolean;
    crm_is_lost?: boolean;
    workspace_members: Pick<
        User,
        "user_id" | "first_name" | "last_name" | "avatar_url"
    >[];
    magic_link?: string;
    archived: boolean;
}

export interface LeadResponse<TLeadType extends LeadRow = LeadRow> {
    count: number;
    next: number | null;
    previous: number | null;
    results: TLeadType[];
}

export const LEADS_KEY = "leads";

export enum LEADS_STATUS {
    DISCOVERY = "D",
    WON = "W",
    LOST = "L",
    IN_PROGRESS = "P",
}

const parseLead = <T extends LeadRow | Lead>(
    lead?: T,
    extensionInstalled?: boolean
) => {
    if (!lead?.trial) return lead;

    const {
        direct_start_url,
        proxy_start_url,
        use_extension_if_installed,
        product: { extension_required },
    } = lead.trial;

    const useDirectUrl =
        extension_required ||
        (use_extension_if_installed && extensionInstalled);

    lead.trial.start_url = useDirectUrl ? direct_start_url : proxy_start_url;

    return lead;
};

const leadsRefetchInterval = (flags: LDFlags, data?: (LeadRow | Lead)[]) => {
    if (flags.preventLeadsFetching) {
        return false;
    }

    return _.some(data, (lead) => lead.trial && !lead.trial.start_url)
        ? SHORT_POLL
        : LONG_POLL;
};

interface UseLeadsParams {
    page?: number;
    search?: string;
    form?: Partial<FilterForm>;
    leadId?: string;
    partnerCompanyFilter?: string[];
    salespersonFilter?: string[];
    statusFilter?: DealFilterParameters;
    dateAddedFilter?: Date[];
    archivedFilter?: boolean;
    partners?: boolean;
    onError?: () => any;
    onSuccess?: () => any;
}

export default function useLeads(params?: UseLeadsParams) {
    const {
        page,
        search,
        form,
        partnerCompanyFilter,
        salespersonFilter,
        statusFilter,
        dateAddedFilter,
        archivedFilter,
        partners,
        onError,
        onSuccess,
    } = params ?? {};
    const { data: user } = useAuth();
    const { extensionInstalled } = useBrowserCapability();
    const { flags } = useFlags();
    const activation_statuses = _(statusFilter?.selectedActivationStatus)
        .map((status) => status === "Activated")
        .join(",");

    const salespersons = salespersonFilter
        ?.map((item) => String(item))
        .join(",");

    const partner_companies = partnerCompanyFilter
        ?.map((item) => String(item))
        .join(",");

    const deal_status = statusFilter?.selectedDealStatus
        ?.map((filter) => filter.key)
        ?.join(",");

    const date_added = dateAddedFilter
        ?.map((item) => String(item.toISOString().split("T")[0]))
        .join(",");

    const paramsKey = queryString.stringify({
        page,
        search,
        activation_statuses,
        partner_companies,
        salespersons,
        deal_status,
        date_added,
        partners,
        archived: archivedFilter,
    });

    return useQuery(
        [LEADS_KEY, paramsKey],
        async () => {
            // call returns a 400 if user is not salesperson
            if (!user?.is_salesperson) {
                return { count: 0, next: null, previous: null, results: [] };
            }

            try {
                const results = await axiosClient.get<LeadResponse<LeadRow>>(
                    `/leads/list`,
                    {
                        params: {
                            page,
                            search,
                            is_active: activation_statuses || undefined,
                            partner_companies: partner_companies || undefined,
                            salesperson: salespersons || undefined,
                            deal_status: deal_status || undefined,
                            date_added: date_added || undefined,
                            partners: partners || undefined,
                            archived: archivedFilter || undefined,
                        },
                    }
                );
                onSuccess?.();

                _.map(results.data.results, (lead) =>
                    parseLead(lead, extensionInstalled)
                );

                return results.data;
            } catch (e) {
                onError?.();
                throw e;
            }
        },
        {
            refetchInterval: (data) =>
                leadsRefetchInterval(flags, data?.results),
            // TODO: There's a problem with the lead request.
            // The first leads request always fails. That's
            // because it calls the salesforce SDK, and the problem
            // arrives when the first salesforce call always fails
            // with a 401 because we need to refresh the session. We
            // are expecting that in the code but for some reason, the
            // exception is not getting caught correctly. The second time
            // we call the request, it works. For now, this is the quick fix
            // that we'll do to gain some time to fix it the correct way.
            // Origin: salesforce_service.py
            retry: (failureCount) => failureCount <= 2,
        }
    );
}

export interface ExternalDeal {
    name: string;
    id: string;
    assigned: boolean;
}

export function useExternalDeal(externalCrmId) {
    return useQuery<ExternalDeal, AxiosError>(
        ["external_crm_id", externalCrmId],
        async () => {
            if (!externalCrmId) {
                return null;
            }
            const results = await axiosClient.get(
                `/crm/deals/${externalCrmId}`
            );
            if (results.status === 200) {
                return results.data;
            }
        },
        {
            retry: false,
        }
    );
}

export function useLead(leadId?: string) {
    const { flags } = useFlags();

    return useQuery(
        [LEADS_KEY, leadId],
        async () => {
            if (!leadId) return;
            const response = await axiosClient.get<Lead>(`/leads/${leadId}`);
            return parseLead(response.data);
        },
        {
            refetchInterval: (data) =>
                leadsRefetchInterval(flags, data ? [data] : undefined),
        }
    );
}

export function useLeadOnce(
    leadId?: string,
    options?: UseQueryOptions<unknown, unknown, Lead>
) {
    return useQuery({
        queryKey: [LEADS_KEY, leadId],
        queryFn: async () => {
            if (!leadId) return;
            const response = await axiosClient.get<Lead>(`/leads/${leadId}`);
            return parseLead(response.data);
        },
        ...options,
    });
}

export function useAddDealMutation(options?: UseMutationOptions) {
    const client = useQueryClient();

    // @ts-ignore
    return useMutation<AxiosResponse, AxiosError, CreateDealMutation>(
        (data) => {
            return axiosClient.post("/leads", data);
        },
        {
            ...options,
            onSuccess: async (response, variables, context) => {
                client.invalidateQueries([LEADS_KEY]);

                if (options?.onSuccess) {
                    options.onSuccess(response, variables as any, context);
                }
            },
        }
    );
}

export interface EditDealMutation {
    lead_id: string;
    status?: string;
}

export function useEditDealMutation(options?: UseMutationOptions) {
    const client = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, EditDealMutation>(
        (data) => {
            return axiosClient.request({
                url: `/leads/${data.lead_id}`,
                data: { status: data.status },
                method: "patch",
            });
        },
        {
            onSuccess: async (response, variables, context) => {
                client.invalidateQueries([LEADS_KEY]);

                if (options?.onSuccess) {
                    options.onSuccess(response, variables as any, context);
                }
            },
            onError: (error, variables, context) =>
                options?.onError?.(error, variables as any, context),
        }
    );
}

export interface UpdateDealMutation extends DealForm {
    skip_existing_check?: boolean;
}

interface UpdateDealMutationContext {
    previousDeal: UpdateDealMutation;
}

export function useUpdateDealMutation(options?: UseMutationOptions) {
    const client = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, UpdateDealMutation>(
        async (data) => {
            if (!data.lead_id) {
                throw Error("Lead id required");
            }

            const results = await axiosClient.put(
                `/leads/${data.lead_id}`,
                data
            );
            if (results.status === 200) {
                return results.data;
            }
        },
        {
            onSuccess: async (response, variables, context) => {
                client.invalidateQueries(USER_KEY);
                client.invalidateQueries(LEADS_KEY);

                options?.onSuccess?.(response, variables as any, context);
            },
            onMutate: async (newDeal) => {
                // Cancel any outgoing queries with the same key
                await client.cancelQueries([LEADS_KEY, newDeal.lead_id]);
                // Optimistically update the cache
                const previousDeal = client.getQueryData<UpdateDealMutation>([
                    LEADS_KEY,
                    newDeal.lead_id,
                ]);

                client.setQueryData<UpdateDealMutation>(
                    [LEADS_KEY, newDeal.lead_id],
                    {
                        ...previousDeal,
                        ...newDeal,
                    }
                );

                return { previousDeal };
            },
            onError: (error, variables, context) => {
                if (
                    (context as unknown as UpdateDealMutationContext)
                        ?.previousDeal
                ) {
                    client.setQueryData<UpdateDealMutation>(
                        [LEADS_KEY, variables.lead_id],
                        (context as unknown as UpdateDealMutationContext)
                            .previousDeal
                    );
                }
                return options?.onError?.(error, variables as any, context);
            },
        }
    );
}

export interface ActivationMutation {
    user_id: string;
}

export function useCreateActivationLinkMutation(options?: UseMutationOptions) {
    return useMutation<AxiosResponse, AxiosError, ActivationMutation>(
        (data) => {
            return axiosClient.request({
                url: `/leads/activation_link`,
                data,
                method: "post",
            });
        },
        {
            onSuccess: async (response, variables, context) => {
                options?.onSuccess?.(response, variables as any, context);
            },
            onError: (error, variables, context) =>
                options?.onError?.(error, variables as any, context),
        }
    );
}

export function useGetSandboxAccessLinkQuery(lead_id: string) {
    const { flags } = useFlags();
    return useQuery<string, AxiosError>(
        [LEADS_KEY, lead_id, "sandbox_access_link"],
        async () => {
            if (!flags.enableCustomerSubdomainsSandbox) {
                return;
            }

            const results = await axiosClient.get(
                `/leads/${lead_id}/sandbox_access_link`
            );
            if (results.status === 200) {
                return results.data?.link;
            }
        }
    );
}

export function useSendActivationEmailMutation(
    lead_id?: string,
    options?: UseMutationOptions
) {
    const client = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, ActivationMutation>(
        (data) => {
            return axiosClient.request({
                url: `/leads/${lead_id}/email_activation_link`,
                data,
                method: "post",
            });
        },
        {
            onSuccess: async (response, variables, context) => {
                client.invalidateQueries([LEADS_KEY, lead_id]);

                options?.onSuccess?.(response, variables as any, context);
            },
            onError: (error, variables, context) =>
                options?.onError?.(error, variables as any, context),
        }
    );
}

export function useLeadProductTrial(lead_id?: string) {
    return useQuery<ProductTrial[], AxiosError>(
        [LEADS_KEY, lead_id, "instances"],
        async () => {
            const results = await axiosClient.get(
                `/leads/${lead_id}/instances`
            );
            if (results.status === 200) {
                return results.data;
            } else {
                return [];
            }
        },
        {
            enabled: !!lead_id,
            refetchInterval: (data) => {
                return _.some(data, (pi) => !pi.start_url)
                    ? SHORT_POLL
                    : LONG_POLL;
            },
        }
    );
}

export function useLeadProductTrials() {
    return useQuery<ProductTrial[], AxiosError>(
        [LEADS_KEY, "instances"],
        async () => {
            try {
                const results = await axiosClient.get(`/leads/instances/`);
                if (results.status === 200) {
                    return results.data;
                } else {
                    return [];
                }
            } catch (e) {
                return [];
            }
        },
        {
            refetchInterval: (data) => {
                return _.some(data, (pi) => !pi.start_url)
                    ? SHORT_POLL
                    : LONG_POLL;
            },
        }
    );
}

interface ExpiryDateMutationVariables {
    expiry_date: string | undefined;
    trial_id: string;
}

export function useUpdateExpiryDateMutation(options?: UseMutationOptions) {
    const client = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, ExpiryDateMutationVariables>(
        (data) => {
            return axiosClient.request({
                url: "leads/change_expiry",
                data,
                method: "PATCH",
            });
        },
        {
            onSuccess: async (response, variables, context) => {
                client.invalidateQueries([LEADS_KEY]);

                options?.onSuccess?.(response, variables as any, context);
            },
            onError: (error, variables, context) =>
                options?.onError?.(error, variables as any, context),
        }
    );
}

interface ArchiveLeadMutationParams {
    lead_id: Lead["lead_id"];
    archived: Lead["archived"];
}

export function useArchiveLeadMutation(options?: UseMutationOptions) {
    const client = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, ArchiveLeadMutationParams>(
        ({ lead_id, archived }) => {
            return axiosClient.patch(`/leads/${lead_id}/archive`, {
                archived,
            });
        },
        {
            onSuccess: async (response, variables, context) => {
                client.invalidateQueries([LEADS_KEY]);

                options?.onSuccess?.(response, variables as any, context);
            },
            onError: (error, variables, context) =>
                options?.onError?.(error, variables as any, context),
        }
    );
}
